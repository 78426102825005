import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Header isWhite />
    <div
      style={{
        minHeight: "95vh",
        paddingTop: "300px",
        textAlign: "center",
        color: "#8D1D1A",
        fontFamily: `"Julius Sans One", sans-serif`,
      }}
    >
      <h1>404: Not Found</h1>
      <p>This page does not exist.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
